import { CurrencyType } from '@/interfaces/subscriptions';

import { CourseModule, PathwayModule } from '../explore';
import { UserSubscription } from './user-subscription';
import { LearnerInformation } from './user-learner-information';
import { UserType } from './user-type';

export interface User {
  id: string;
  email: string;
  name: string;
  surname: string;
  settings: UserSettings;
  role: string;
  isActivated?: boolean;
  clientId?: string;
  clientName?: string;
  courseModules: CourseModule[];
  pathwayModules: PathwayModule[];
  checklist: 'intro' | 'path' | 'teams' | 'survey' | 'result';
  teams: string[];
  favoriteBooks?: string[];
  favoriteChapters?: string[];
  favoriteInsights?: string[];
  favoriteTechniques?: string[];
  favoriteTools?: string[];
  subscription?: UserSubscription;
  crm?: {
    contactId: string;
  };
  learnerInformation?: LearnerInformation;
  type: UserType;
  certificationInterests?: { contentId: string; contentName: string }[];
}

export interface UserSettings {
  b2cAccess?: boolean;
  currency?: CurrencyType;
  country?: string;
}

export enum UserAccountStatus {
  Active = 'active',
  Inactive = 'inactive',
}
